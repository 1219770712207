<template>
    <div class="hero">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-2 hidden-sm hidden-xs "></div>
                <div class="col-sm-8">
                    <div class="dddd">
                        <div class="d-if" >
                            <h1>{{ $t('header.live-msg-1') }}</h1>
                            <h3>{{ $t('header.live-msg-2') }}</h3>
                            <br>
                            <button class="btn bg-orange" @click="download">
                                <i class="icofont-brand-android-robot"></i>
                                {{ $t('header.live-dw') }}
                            </button>
                            <br>
                            <button class="btn bg-orange">
                                <i class="icofont-brand-apple"></i>
                                {{ $t('header.live-dp') }}
                            </button>
                        </div>
                        <img :src="`${publicPath}images/phones.png`" alt="promo" class="img-promo">
                    </div>
                </div>
                
                <div class="col-sm-2 hidden-sm hidden-xs"></div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            publicPath: process.env.BASE_URL || '/',
        }
    },
    methods: {
        download: function() {
            window.open('https://app.rahisibet.com','_blank');
        }
    }
}
</script>
<style scoped>
.hero {
	width: 100%;
	position: relative;
	overflow: hidden;
    /* background-color: var(--bg-blue); */
    margin-top: -10px;
}
.hero::before {
	content: "";
	position: absolute;
	right: -100%;
	top: 20%;
	width: 250%;
	height: 200%;
	z-index: -1;
	/* background-color: #e8ecf5; */
	transform: skewY(135deg);
}
.hero h1 {
    /* color: var(--bg-dark-blue); */
    font-size: 55px;
}
.dddd {
    display: grid;
    gap: 10px;
    align-items: center;
}
.img-promo {
    /* width: 100%; */
    max-height: 550px;
}
.sport {
    padding:  50px 0px;
}
.btn.bg-orange {
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
}
</style>